import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import packageJson from '../../../package.json';
import LinearProgress from '@mui/material/LinearProgress';
// global.appVersion = packageJson.version;
// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
	// const versionsA = versionA.split(/\./g);

	// const versionsB = versionB.split(/\./g);
	// while (versionsA.length || versionsB.length) {
	//   const a = Number(versionsA.shift());
	//   const b = Number(versionsB.shift());
	//   console.log('a', a);
	//   console.log('b', b);
	//   // eslint-disable-next-line no-continue
	//   if (a === b) continue;
	//   // eslint-disable-next-line no-restricted-globals
	//   return a > b || isNaN(b);
	// }
	return versionA !== versionB;
};

const refreshCacheAndReload = () => {
	console.log('Clearing cache and hard reloading...');
	console.log(caches);
	if (caches) {
		// Service worker cache should be cleared with caches.delete()

    caches.keys().then(async function(names) {
      await Promise.all(names.map(name => caches.delete(name)));
      });

		// caches.keys().then(function (names) {
		// 	for (let name of names) caches.delete(name);
		// });
	}
console.log('SHOULD REFRESH')
	// delete browser cache and hard reload
	window.location.reload(true);
};

function CacheBuster({ enabled, currentCacheVersion, ...props }) {
	console.log(enabled);
	if (!enabled) {
		console.log('Cache busting is disabled...');
	}

	const [loading, setLoading] = useState(true);
	const [isLatestVersion, setIsLatestVersion] = useState(false);

	useEffect(() => {
		if (enabled) {
			// fetch('/meta.json', { cache: 'no-store' })
      console.log('MAKE REQUEST')
			fetch(`/meta.json`, { cache: 'no-cache' })
				.then((response) => response.json())
				.then((meta) => {
					const latestVersion = meta.version;
					const currentVersion = currentCacheVersion;
          // const currentVersion = global.appVersion;

					console.log('latestVersion', latestVersion);
					console.log('currentVersion', currentVersion);

					const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
					if (shouldForceRefresh && !isLatestVersion) {
						console.log(`We have a new version - ${latestVersion}. Should force refresh`);
						setLoading(true);
						setIsLatestVersion(false);
						refreshCacheAndReload();
					} else {
						console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
						setLoading(false);
						setIsLatestVersion(true);
					}
				});
		}
	}, []);

	return enabled ? loading ? <LinearProgress /> : props.children : props.children;

	// return props.children({ loading, isLatestVersion, refreshCacheAndReload });
}

CacheBuster.propTypes = {};

export default CacheBuster;
