// React core
import React from 'react';

// redux && redux store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history, store, persistor } from './store.js';
import { LocalizeProvider } from 'react-localize-redux';

// Our history
import { ConnectedRouter } from 'connected-react-router';

// Our "pages" main components
import Main from './Main.js';
// import { StylesProvider } from '@material-ui/core/styles';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
// import CacheBuster from 'react-cache-buster';
import CacheBuster from './components/Generic/CacheBuster.js';
import LinearProgress from '@mui/material/LinearProgress';
import packageJson from '../package.json';

function App() {
	const isProduction = process.env.NODE_ENV === 'production';

	const { version } = packageJson;

	console.log(process.env.NODE_ENV);
	console.log(version);

	// if (typeof window !== 'undefined') {
	//   const { fetch: originalFetch } = window
	//   window.fetch = async (...args) => {
	//     const [resource, ...rest] = args

	//     let modifiedResource = resource
	//     if (resource === '/meta.json') {
	//       modifiedResource = `/meta.json?v=${Date.now()}`
	//     }
	//     const response = await originalFetch(modifiedResource, ...rest)
	//     return response
	//   }
	// }
console.log(isProduction)
	return (
		// <CacheBuster enabled={isProduction} currentCacheVersion={version}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConnectedRouter history={history}>
						<LocalizeProvider store={store}>
							<Main />
						</LocalizeProvider>
					</ConnectedRouter>
				</PersistGate>
			</Provider>
		// </CacheBuster>
		// <CacheBuster
		//   currentVersion={version}
		//   isEnabled={isProduction} //If false, the library is disabled.
		//   isVerboseMode={true} //If true, the library writes verbose logs to console.
		//   loadingComponent={<LinearProgress />} //If not pass, nothing appears at the time of new version check.
		//   reloadOnDowngrade={true}
		//   metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		// >
	);
}

export default App;
