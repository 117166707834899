// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
	return new Promise((resolve, reject) => {
    // console.log('lazy retry');
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    // console.log(hasRefreshed)
		// try to import the component
		componentImport()
			.then((component) => {
        // console.log('resolve');
				window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
				resolve(component);
			})
			.catch((error) => {
        console.log(error);
        console.log(hasRefreshed);
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
					return window.location.reload(); // refresh the page
				}
				reject(error); // Default error behaviour as already tried refresh
			});
	});
};

export { lazyRetry };
